import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Fade from 'react-reveal/Fade'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  info?: string
  title?: string
}

export const VipBanner = memo(function VipBanner({
  cta,
  description,
  image,
  info,
  title,
}: Props) {
  return (
    <Container>
      {image ? <LazyLoadImage effect="blur" {...image} /> : null}
      <Wrapper row wrap>
        <LeftSide>
          <Fade left>
            <>{title ? <Title>{title}</Title> : null}</>
          </Fade>
        </LeftSide>
        <RightSide>
          {info ? (
            <Fade bottom distance="3.75rem">
              <Info dangerouslySetInnerHTML={{ __html: info }} />
            </Fade>
          ) : null}
          {description ? (
            <Fade bottom distance="3.75rem">
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </Fade>
          ) : null}
          {cta ? (
            <Fade bottom distance="3.75rem">
              <CTA {...cta} />
            </Fade>
          ) : null}
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  padding: 7.1875rem 10vw 7.5rem 0;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    padding: 5.625rem 8.454vw;
  }
`

const Wrapper = styled(FlexBox)`
  position: relative;
  z-index: 2;
`

const LeftSide = styled.div`
  width: 50%;
  padding: 0 7.639vw 0 10vw;

  @media (max-width: 1199px) {
    padding: 0 8.454vw 0 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 0.75rem;
    padding: 0;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 200;
  letter-spacing: 0.3125rem;
  line-height: 3.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.1875rem;
    letter-spacing: 0.1875rem;
    line-height: 2.625rem;
  }
`

const RightSide = styled.div`
  width: 50%;
  max-width: 32.639vw;

  @media (max-width: 1199px) {
    max-width: none;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Info = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
  margin-bottom: 1.5rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.625rem;
`

const CTA = styled(Button)`
  display: block;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  text-align: center;
  &:after {
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
  }
`
