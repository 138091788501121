import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo, useState } from 'react'
import { Check, ChevronDown } from 'react-feather'
import Fade from 'react-reveal/Fade'

export interface Props {
  languageCode: string
  services?: string[]
  subtitle?: string
  title?: string
}

export const VipServices = memo(function VipServices({
  languageCode,
  services,
  subtitle,
  title,
}: Props) {
  if (services && services?.length < 1) {
    return null
  }

  const [expandedServices, setExpandedServices] = useState(false)

  return (
    <Container dial={5} row tag="section" wrap>
      <LeftSide>
        <Fade left>
          <Wrapper>
            {title ? <Title>{title}</Title> : null}
            {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          </Wrapper>
        </Fade>
      </LeftSide>
      <RightSide>
        {services ? (
          <Fade bottom distance="3.75rem">
            <Services
              className={
                services?.length > 6 && !expandedServices
                  ? 'compact'
                  : undefined
              }
              row
              wrap
            >
              {services?.map((item, index) => (
                <Service dial={4} key={index} row>
                  <SVG dial={5}>
                    <Check />
                  </SVG>
                  <Label>
                    {
                      // @ts-ignore
                      item.label
                    }
                  </Label>
                </Service>
              ))}
            </Services>
            {services?.length > 6 ? (
              <Expander
                className={expandedServices ? 'hidden' : undefined}
                onClick={() => setExpandedServices(true)}
              >
                {useVocabularyData('show-all', languageCode)}
                <ChevronDown />
              </Expander>
            ) : null}
          </Fade>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 2.1875rem 0 5.9375rem;
`

const LeftSide = styled.div`
  width: 40%;
  background: url('/pixels.png') no-repeat center;
  background-size: contain;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.875rem;
  padding: 6.25rem 10vw;
  position: relative;

  @media (max-width: 1199px) {
    padding: 3.75rem 8.454vw;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 3.75rem;
  }
`

const Wrapper = styled.div`
  max-width: 270px;
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  margin: auto;
  padding: 5.5rem 2.625rem;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem;
  }

  @media (max-width: 1023px) {
    max-width: none;
    padding: 1.875rem 0;
    text-align: center;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
`

const RightSide = styled.div`
  width: 60%;
  padding-right: 2.639vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 8.454vw;
  }
`

const Services = styled(FlexBox)`
  @media (max-width: 767px) {
    &.compact {
      height: 18.75rem;
      overflow: hidden;
      position: relative;
      &:after {
        content: '';
        width: 100%;
        height: 6.25rem;
        background: linear-gradient(
          ${rgba(theme.colors.variants.neutralDark1, 0)},
          ${rgba(theme.colors.variants.neutralDark1, 1)}
        );
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
`

const Service = styled(FlexBox)`
  width: calc(50% - 2.708vw);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.9375rem;
  letter-spacing: 0.0175rem;
  line-height: 1.25rem;
  margin: 0 2.708vw 1.625rem 0;
  padding-left: 1.0625rem;
  position: relative;

  @media (max-width: 1023px) {
    padding-left: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
`

const SVG = styled(FlexBox)`
  width: 2.25rem;
  height: 2.25rem;
  background: ${rgba(theme.colors.variants.neutralLight4, 0.16)};
  border-radius: 50%;
  margin-right: 0.75rem;
  svg {
    width: auto;
    height: 1.25rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryLight1};
  }
`

const Label = styled.label`
  width: calc(100% - 3rem);
`

const Expander = styled.div`
  display: none;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0875rem;
  line-height: 0.9375rem;
  margin-top: 1.875rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  &.hidden {
    display: none;
  }
  svg {
    display: block;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: auto;
  }

  @media (max-width: 767px) {
    display: block;
  }
`
