import styled from '@emotion/styled'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

export interface Props {
  title: string
}

export const VipIntro = memo(function VipIntro({ title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <Fade bottom distance="3.75rem">
          <Title>{title}</Title>
        </Fade>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding-top: 7.3125rem;

  @media (max-width: 1199px) {
    padding-top: 5.625rem;
  }

  @media (max-width: 1023px) {
    padding-bottom: 3.75rem;
  }
`

const Wrapper = styled.div`
  max-width: 760px;
  background: url('/pixels.png') no-repeat center;
  background-size: contain;
  margin: auto;
  padding: 10.375rem 0;

  @media (max-width: 1199px) {
    padding: 7.5rem 0;
  }
`

const Title = styled.h2`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.75rem;
  font-weight: 200;
  line-height: 4.5rem;
  padding: 2.75rem 3.0625rem;
  text-align: center;

  @media (max-width: 1023px) {
    font-size: 2.1875rem;
    line-height: 2.625rem;
    padding: 1.875rem 8.454vw;
  }
`
